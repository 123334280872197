// App.js
import React from 'react';
import './App.css';
import RotatingBanner from './RotatingBanner/RotatingBanner';
import BionicleGif from "./bionicle.gif"
import ButtonContainer from './Buttons/Buttons.js';
function App() {
  return (
    <div className="App">
      <ButtonContainer />
      <RotatingBanner />
      
      <img src={BionicleGif} alt="Bionicle" className="bionicle" />
    </div>
  );
}

export default App;