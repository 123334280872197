import React from 'react';
import './Buttons.css';
import twitterIcon from './twitter.jpg';
import degenIconn from './degen.jpg';


const ButtonContainer = () => {
  return (
    <div className="button-container">
      <button className="shared-button">
        <a href="https://twitter.com/FORTNITEBI13312" target="_blank" rel="noopener noreferrer">
          <img src={twitterIcon} alt="Twitter" className="button-icon" />
          <span className="button-text">Twitter</span>
        </a>
      </button>
      <button className="shared-button">
        <a href="https://degen.fund/" target="_blank" rel="noopener noreferrer">
          <img src={degenIconn} alt="degen" className="button-icon" />
          <span className="button-text">degen.fund</span>
        </a>
      </button>
    </div>
  );
};

export default ButtonContainer;